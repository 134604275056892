<template>
  <div official-league :style="officialLeagueDataStyle" :class="upRouteGameId">
    <PSection class="progress-holder" theme="white">
      <div class="inside">
        <SectionTitle title="OFFICIAL LEAGUE" title-ko="공식 대회" />
        <a
          class="btn-ask"
          :href="officialLeagueDataDiscordLink"
          target="_blank"
          @click="trackEvent(`${upRouteGameId}_Main_discord_btn`, 'click', `${upRouteGameId}`)"
          v-prx:intro="{ r: [1, 4], ty: [20, 0], o: [0, 1] }"
        >
          <span v-if="routeParamsId === 'tft_weeklycup'">대회 문의</span>
          <span v-else>대회운영 문의<em>|</em>팀 찾기</span>
        </a>
        <slot name="detail" />
      </div>
      <p class="bg" v-if="officialLeagueDataIsBgExist" />
      <slot name="hero" />
    </PSection>
    <PSection v-if="cardList" class="slider-holder league" theme="white">
      <div class="card-list-holder">
        <ToggleTab :value="week" @input="getCardList" :tabs="officialLeagueDataTabs" />
        <div class="msg" v-if="routeParamsId === 'val_shotval' && (cardList || []).length"><b>원하는 날짜</b>에 참여 해보세요! <em>(예선 1,2차 중복참여 가능)</em></div>
        <div class="msg-tft" v-if="routeParamsId === 'tft'">
          <h4>원하는 기간에 참가 신청해보세요!</h4><em>( 중복 참여 가능 )</em>
        </div>
        <Slider
          v-if="(cardList || []).length"
          :option="option"
          ref="slider"
          class="card-slider"
          @transitionEnd="transitionEnd"
          @transitionStart="transitionStart"
          @touchIn="touchStart"
          @touchOut="touchEnd"
          :info="cardList"
          navigation slides-per-view="auto"
        >
          <template v-slot:slide="{info, idx}">
            <OfficialGameCard
              :key="info.id"
              :item="info"
              :data-id="idx"
              :class="['card']"
              @click="selectedCard($event, idx)"
            />
          </template>
        </Slider>
        <NoData v-else text="등록된 경기가 없습니다." />
        <div class="arrow-wrap" v-show="(matchedMedia === 'DS' || matchedMedia === 'DL') && cardList.length > 4">
          <button class="arrow-left" :class="{'end': selectedId === 0}" @click="sliderMove(-1)">
            <img src="/img/pages/icon/Arrow-left.svg" alt="prev" />
          </button>
          <button class="arrow-right" :class="{'end': selectedId === cardList.length-1}" @click="sliderMove(1)">
            <img src="/img/pages/icon/Arrow-right.svg" alt="next" />
          </button>
        </div>
      </div>
    </PSection>
    <slot name="gameInfo" />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Slider from 'shared/components/common/Slider.vue';
import gtag from 'shared/mixins/gtag';
import ripple from '@/directives/ripple';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import OfficialGameCard from '@/views/components/gameHome/OfficialGameCard.vue';
import { millisecond } from '@/utils/time';
import ToggleTab from '@/views/components/common/tab/ToggleTab.vue';
import NoData from '@/views/components/common/NoData.vue';

export default {
  name: 'OfficialLeague',
  mixins: [gtag],
  props: {
    officialLeagueData: { type: Object, default: () => ({}) },
    step: { type: Number, default: 0 },
    ended: { type: Boolean, default: false },
  },
  directives: { ripple },
  components: {
    OfficialGameCard,
    SectionTitle,
    Slider,
    ToggleTab,
    NoData,
  },
  data() {
    return {
      week: -1,
      slider: null,
      selectedId: 0,
      resizeId: null,
      touchId: null,
      delayId: null,
      delay: false,
      startedX: 0,
      today: null,
      init: true,

      cardOption: {
        cursor: 0,
        page: 0,
        order: 'schedule.applyStart ASC',
        q: 'groupCode eq RIOT_BATTLE_ACADEMIA_1',
      },
      cardList: null,
      option: {
        slidesPerView: 'auto',
        preventClicks: true,
        preventClicksPropagation: false,
        observer: true,
        observeParents: true,
        touchRatio: 1,
        freeMode: true,
      },
      leagueTab: ['all', 'gold', 'diamond', 'radiant'],
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
    matchedMedia() {
      return this.$store?.state?.browser?.matchedMedia;
    },
    officialLeagueDataStyle() {
      return _.get(this.officialLeagueData, 'style', {});
    },
    officialLeagueDataIsBgExist() {
      return _.get(this.officialLeagueData, 'isBgExist', true);
    },
    officialLeagueDataWeeksKv() {
      return _.get(this.officialLeagueData, 'weeksKv', {});
    },
    officialLeagueDataTabs() {
      return _.get(this.officialLeagueData, 'tabs', []);
    },
    officialLeagueDataQuery() {
      return _.get(this.officialLeagueData, 'q', '');
    },
    officialLeagueDataStepTime() {
      return _.get(this.officialLeagueData, 'stepTime', []);
    },
    officialLeagueDataDummyTabIndex() {
      return _.get(this.officialLeagueData, 'dummyTabIndex', null);
    },
    officialLeagueDataDummyCards() {
      return _.get(this.officialLeagueData, 'dummyCards', []);
    },
    officialLeagueDataDiscordLink() {
      return _.get(this.officialLeagueData, 'discordLink', '');
    },
    timeValue() {
      let s;
      let e;

      switch (this.week) {
        case 0:
          s = millisecond(this.officialLeagueDataWeeksKv?.w1?.s);
          e = millisecond(this.officialLeagueDataWeeksKv?.w1?.e);
          break;
        case 1:
          s = millisecond(this.officialLeagueDataWeeksKv?.w2?.s);
          e = millisecond(this.officialLeagueDataWeeksKv?.w2?.e);
          break;
        case 2:
          s = millisecond(this.officialLeagueDataWeeksKv?.w3?.s);
          e = millisecond(this.officialLeagueDataWeeksKv?.w3?.e);
          break;
        case 3:
          s = millisecond(this.officialLeagueDataWeeksKv?.w4?.s);
          e = millisecond(this.officialLeagueDataWeeksKv?.w4?.e);
          break;
        default:
          throw Error('error');
      }
      return `schedule.start GOE ${s} and schedule.end LT ${e}`;
    },
    routeParamsId() {
      return this.$route.params.id;
    },
    base() {
      return this.$store.state.baseInfo;
    },
  },
  methods: {
    getMatrixX() {
      const t = this.$el.querySelector('.card-slider .swiper-wrapper');
      const { transform } = t.style;
      const r = /translate3d\((?<x>.*?)px, (?<y>.*?)px, (?<z>.*?)px/;
      const v = r.exec(transform);
      return v ? v.groups.x : 0;
    },
    selectedCard($e, id) {
      if (Math.abs(this.startedX - this.getMatrixX()) > 10) return;
      clearTimeout(this.touchId);
      this.cardSorting($e, id);
    },
    transitionEnd() {
      if (this.delay) return;
      this.cardSorting();
    },
    transitionStart() {
      clearTimeout(this.touchId);
    },
    touchStart() {
      this.startedX = this.getMatrixX();
      this.delay = false;
      clearTimeout(this.delayId);
    },
    touchEnd() {
      if (Math.abs(this.startedX - this.getMatrixX()) < 10) return;
      this.touchId = setTimeout(() => {
        this.cardSorting();
      }, 100);
    },
    sliderMove(v) {
      if (this.ended) return;
      const items = this.$el.querySelectorAll('.card.swiper-slide');
      let startId = v.length ? Number(v[0]) : Number(v);
      startId = v.length ? startId : this.selectedId + startId < 0 ? 0 : this.selectedId + startId >= items.length ? items.length - 1 : this.selectedId + startId;

      this.cardSorting(items[startId], startId);

      if (v.length > 1) {
        _.forEach(v, o => {
          items[o].classList.add('on');
        });
      }
    },
    cardSorting($e, id) {
      if (this.ended) return;
      const mSize = window.innerWidth < 1024;
      const items = this.$el.querySelectorAll('.card.swiper-slide');
      const box = this.$el.querySelector('.card-slider .swiper-container');
      const wrapper = this.$el.querySelector('.card-slider .swiper-wrapper');
      if (!box) return;
      const boxHarf = box.clientWidth / 2;

      let total = 0;
      items.forEach(item => (total += item.clientWidth + (mSize ? 26 : 40)));
      total -= mSize ? 12 : 30;

      const max = (total - box.clientWidth) * -1;
      const coverX = Number(this.getMatrixX());
      let itemId = Math.floor(items.length * (coverX / max)) || 0;
      itemId = itemId >= items.length ? items.length - 1 : itemId < 0 ? 0 : itemId;
      const target = $e || items[itemId];
      if (!target) return;
      const { x } = target.getBoundingClientRect();

      items.forEach(item => {
        if (item === target) {
          item.classList.add('on');
        } else {
          item.classList.remove('on');
        }
      });
      //
      let v = coverX - (x + target.clientWidth / 2 - (mSize ? 40 : 100) - boxHarf);
      v = v > 0 ? 0 : v < max ? max : v;
      wrapper.style.transform = `translate3d(${v}px, 0, 0)`;
      wrapper.style.transitionDuration = '300ms';

      this.selectedId = Number(id) >= 0 ? id : itemId;
      this.delay = true;
      this.delayId = setTimeout(() => {
        this.delay = false;
      }, 1000);
    },

    async getCardList(v, item) {
      this.trackEvent(`arena_tab_${this.leagueTab[v]}`, 'click', `${this.upRouteGameId}`);
      if (this.$refs.slider) {
        const wrapper = this.$el.querySelector('.card-slider .swiper-wrapper');
        wrapper.style.transform = `translate3d(0, 0, 0)`;
      }
      if (this.week === v) return;
      this.week = v;
      if (this.officialLeagueDataDummyTabIndex === v) {
        this.cardList = this.officialLeagueDataDummyCards;
        setTimeout(() => {
          this.sliderMove(0);
        }, 300);
        return;
      }

      const params = _.cloneDeep(this.cardOption);
      if (item && item.q && item.order) {
        params.q = item.q;
        params.order = item.order;
      } else params.q += ` and ${this.timeValue}`;

      const res = await this.$services.home.getArena(params);
      this.cardList = res.items;

      const ids = [];
      _.forEach(this.cardList, (o, id) => {
        if (moment(o.schedule.start).format('MM-DD') === this.today) ids.push(id);
      });

      if (['wr_wariwari', 'val_shotval', 'tft'].includes(this.$route.params.id) && !ids.length) {
        const onGoingId = _.findIndex(this.cardList, o => o.statusTag === 'ONGOING' || o.statusTag !== 'END');
        if (onGoingId >= 0) ids.push(onGoingId);
      }

      this.selectedId = ids.length ? ids[0] : 0;

      setTimeout(() => {
        this.init = false;
        if (this.$route.params.id === 'val_shotval') {
          this.sliderMove(ids);
        } else {
          this.sliderMove(0);
        }
      }, this.init ? 700 : 300);
    },
    resize() {
      clearTimeout(this.resizeId);
      if (!this.ended) {
        this.resizeId = setTimeout(this.cardSorting, 600);
      }
    },
  },
  mounted() {
    this.today = moment().format('MM-DD');
    this.cardOption.q = this.officialLeagueDataQuery;

    if (!this.ended) {
      this.selectedId = this.step;
      this.getCardList(this.selectedId, this.officialLeagueDataTabs[this.selectedId]);
      window.addEventListener('resize', this.resize);
    }
  },
  beforeDestroy() {
    clearTimeout(this.delayId);
    clearTimeout(this.touchId);
    clearTimeout(this.resizeId);
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[official-league] { .c(white); overflow: hidden; .rel; .z(1);
  .progress-holder { .pt(62); .rel;
    .inside { .rel;
      [section-title] { .pl(20); .rel; z-index: 3; .ib; .vam; .mt(6); .mr(22);}
      .btn-ask {.wh(40, 40); .rel; z-index: 2; .abs; .br(8); .bgc(#5b91f5); .ib; .vam;
        span {.hide;}
        &:before {.cnt; .wh(24); .contain('@{icon}/discord.svg'); .lt(50%, 50%); .t-xyc; .abs;}
      }
    }
    .bg {.abs; .wh(100%); .bg('@{bg}/bg.png'); z-index: 0; .lt(0, 0); .o(.2);}
  }
  .slider-holder { .rel; z-index: 2; transition: .5s; .t-y(0); .o(1); .pt(40); .pb(20); .ani-base;
    .intro-up(1, { .ani-on;});
    .tab { z-index: 2; .tc; .mb(50); .pr(18); .pl(18);
      > button { .font-g; .medium; .bgc(#3d3f45); .c(rgba(255, 255, 255, .5)); .br-t(8); .wh(78, 52); .fs(16, 16);
        &.on { .c(black); .bgc(white); }
        & + button { .ml(4); }
      }
      &:after { .cnt; .wh(100%, 1); .bgc(#3d3f45); margin: 0 auto; .-box; }
    }
    //&[data-i='-']{.t-y(200); .o(0);}
    [slider] {.tc; .-box; .p(0, 20); .pb(25); .min-h(546);
      .swiper-container {.ib; width: auto; overflow: visible; .max-w(100%);
        .swiper-wrapper {width: auto;
          .swiper-slide { .ml(12); .max-w(262);
            &:nth-of-type(1) {.ml(0);}
          }
          > .swiper-slide {.flex; .flex-jc;}
        }
      }
    }
    [no-data] { .mt(-25); .bgc(transparent);
      > p { .fs(15); .c(#ffffffa1); }
    }
    .arrow-wrap { .hide; .abs; .b(268); left: 0; z-index: 1; .w(100%);
      .end { .hide;}
      img {.vam;}
      .arrow- {
        &left {.abs;.lt(20, 0);z-index: 1;.wh(52, 52);.br(50%);.bgc(rgba(255, 255, 255, 0.1));}
        &right {.abs;.rt(20, 0);z-index: 1;.wh(52, 52);.br(50%);.bgc(rgba(255, 255, 255, 0.1));}
      }
    }
  }
  @media (@tl-up) {
    .progress-holder { .p(100, 0, 0);
      .inside {
        .btn-ask {.wh(218, 48); .fs(14, 48px); .pl(56); .abs; .rt(58, 0);
          span {.ib;
            em {.m(0, 10);}
          }
          &:before { .wh(28); .l(20); .t-y(-50%); .mt(1);}
        }
        [section-title] { .mb(0); .pl(58);}
      }
      .bg {.h(95%); .o(.3);}
    }
    .slider-holder { .pt(0); .p(56, 58, 100);
      .tab { .mt(0); .mb(82); .tl; .pr(0); .pl(0);
        > button { .wh(200, 56); .fs(20, 16);
          & + button { .ml(8); }
        }
        // &:after { .cnt; .wh(100%, 1); .bgc(#3D3F45); }
      }
      [slider] { .p(0); .min-h(626);
        .swiper-container {
          .swiper-wrapper {
            .swiper-slide {.max-w(340);}
          }
        }
      }
      [no-data] {
        > p { .fs(18); }
      }
      .arrow-wrap { .block; }
    }
  }

  @media (@ds-up) {
    .progress-holder { .p(100, 0, 0);
      .inside { .w(@screen-ds-min); .mh-c; .rel;
        .btn-ask {.rt(0);}
        [section-title] { .mb(0); .pl(0);}
      }
      .bg {.h(110%); .o(1);}
    }
    .slider-holder { .rel; z-index: 2; .p(40, 0, 100); .mb(0);
      .card-list-holder { .w(@screen-ds-min); margin-right: auto; margin-left: auto;
        .tab { .mb(86); }
        .bts-week {.tl;}
        [slider] {.pt(0);}
      }
      .arrow-wrap {
        .arrow- {
          &left { .lt(40, 0); }
          &right { .rt(40, 0); }
        }
      }
    }
  }
}
</style>
