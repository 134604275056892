<template>
  <div official-game-card :class="['swiper-slide off', gameId]" @click="click">
    <div class="card-holder">
      <div v-if="isToday" class="date-box">
        <div class="inner">
          <p class="today">오늘</p>
          <p class="month" ri>{{ startMonth }}</p>
          <p class="date">{{ startDay }}</p>
        </div>
      </div>
      <div class="title">
        <em v-if="statusTag && isTagShouldHide" class="status-tag ready" :class="item.statusTag">{{ statusTag }}</em>
        <h4>{{ title }}</h4>
      </div>
    </div>
    <div class="char-holder">
      <ImageAsset :url="imageObj.image" />
      <div class="bg" :style="`background:${backgroundColor}`">
      </div>
    </div>
    <div class="info-holder">
      <ul>
        <li>
          <span>대회 시간</span>
          <em>{{ startTime }} KST</em></li>
        <li>
          <span>대회 모드</span>
          <em>{{ modeType || modeSize }}</em></li>
        <li>
          <span>대회 날짜</span>
          <em>{{ startDateFormat }}</em></li>
        <li>
          <span>남은 시간</span>
          <CountDown v-if="item.schedule" :end-time="item.schedule.start" />
        </li>
      </ul>
      <ButtonRipple @click="moveToArena">
        <span v-if="statusTag === '접수중'">참가 신청</span>
        <span v-else>대회 이동</span>
      </ButtonRipple>
    </div>

  </div>
</template>

<script>
import moment from 'moment-timezone';
import gtag from 'shared/mixins/gtag';
import { MAIN_URL } from '@/env';
import ImageAsset from '@/views/components/common/ImageAsset.vue';
import CountDown from '@/views/components/common/CountDown.vue';
import ButtonRipple from '@/views/components/common/button/ButtonRipple.vue';

moment.tz.setDefault('Asia/Seoul');

export default {
  name: 'OfficialGameCard',
  components: {
    ImageAsset,
    CountDown,
    ButtonRipple,
  },
  mixins: [gtag],
  props: {
    active: Boolean,
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    gameId() {
      return this.$route.params.id;
    },
    upRouteGameId() {
      return this.gameId.toUpperCase();
    },
    imageObj() {
      return this.item.images.vertical;
    },
    backgroundColor() {
      return this.imageObj.backGroundColor;
    },
    title() {
      return this.item.title || '';
    },
    statusTag() {
      switch (this.item.statusTag) {
        case 'PRE':
          return '접수대기';

        case 'READY':
          return '접수중';

        case 'WAITING_APPLY':
          return '접수대기';

        case 'APPLYING':
          return '접수중';

        case 'ONGOING':
          return '진행중';

        case 'END':
          return '종료';
        default:
          break;
      }

      return null;
    },
    modeSize() {
      const size = this.item.management.mode;
      return `${size} : ${size}`;
    },
    modeType() {
      return this.item.uniqueOption?.uniqueOptionAndValue?.MODE_TYPE;
    },
    startDateFormat() {
      return moment(this.item.schedule.start).format('MM월 DD일');
    },
    startMonth() {
      return moment(this.item.schedule.start).format('MM월');
    },
    startDay() {
      return moment(this.item.schedule.start).format('DD');
    },
    startTime() {
      return moment(this.item.schedule.start).format('HH:mm');
    },
    remainStartTime() {
      const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
      const endtime = moment(moment(this.item.schedule.start).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
      const diffDay = moment(endtime).diff(moment(now), 'days');

      return diffDay < 10 ? Number(diffDay.toString().padStart(2, '0')) : diffDay;
    },
    isToday() {
      const format = 'YYYY-MM-DD';
      const contestDate = moment(this.item.schedule.start).format(format);
      const today = moment().format(format);

      return moment(contestDate).isSame(today);
    },
    isTagShouldHide() {
      return this.startDateFormat !== '08월 09일';
    },
  },
  methods: {
    click() {
      if (this.$el.classList.contains('on')) this.moveToArena();
      else this.$emit('click', this.$el);
    },
    moveToArena() {
      this.trackEvent(`Main_arena_go`, `click_arena_${this.item.id}`, `${this.upRouteGameId}`);
      if (this.item.dummy) {
        if (this.item.dummyLink) {
          window.open(this.item.dummyLink);
        } else {
          this.$toast('toast.ready', { type: 'fail' });
        }
      } else {
        window.location.href = `${MAIN_URL}/arena/ri/${this.$route.params.id}/${this.item.id}/info`;
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[official-game-card] { .w(262); transition: .3s; .t-y(0);
  .card-holder { .wh(100%, 290); .tl; .rel; .pointer; transition: .5s;
    .date-box { .abs; z-index: 4; .bgc(tranparent); .p(0); .lt(20, 34);
      .inner { .abs; z-index: 5; .wh(42, 60); .bgc(#191919); .br(2); .p(7, 0); .-box; .lt(50%); .t-xc; object-fit: cover; .c(white);
        .today {.tc; .fs(12); .c(white); .regular; .abs; .lt(50%, -20); .t-xc;}
        .month:after {.bgc(white);}
      }
      &:before {.cnt; .abs; .wh(60, 95); .br(8); .bgc(#d13639); z-index: -1; .lt(50%, 20); .t-xyc;}
    }
    .title { .abs; .lt(12, 213); z-index: 3; width: calc(100% - 24px); transition: .3s;
      .status-tag {.abs; .lt(0, -30);}
      h4 {.mt(4); .ellipsis(2); .fs(20, 28px); .ls(-1); text-shadow: 0 0 rgba(0, 0, 0, .3), 0 0 rgba(0, 0, 0, .3), 1px 1px rgba(0, 0, 0, .3), 1px 1px rgba(0, 0, 0, .3);}
    }
  }
  .char-holder {.abs; .lt(0, 0); .wh(100%, 290); transition: .3s; z-index: 2; overflow: hidden; .pointer;
    img {.wh(100%, 290); .abs; .lb(50%, 0); .t-xc; object-fit: cover; transition: .3s; z-index: 1; object-position: bottom;}
    .bg {background: linear-gradient(#f5b93a, #72591f); .wh(100%, 255); .abs; .lb(0, 0); z-index: 0; transition: .3s; .br(8);
      .gradi {
        .abs; .lb(0, 0); .r(0); .h(30%); z-index: 1; background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
      }
    }
    //em{.block; .wh(100%,200); .bg-s(360,200); .bg-x(-120); .bg-y(0); .lt(0,-20); .abs;}
  }
  .info-holder { .w(100%); transition: .3s; .hidden; .o(0); .t-y(-100%);
    ul { .p(20, 14); .bgc(#24262c); .br-b(8);
      li {.fs(16); .tl; .mt(14); .pl(32); .rel;
        > * {.ib;}
        &:before {.cnt; .abs; .wh(24); .contain('@{icon}/Arena_access_time_w.svg'); .lt(0, -2); .o(.7);}
        &:nth-of-type(1) {.mt(0);}
        &:nth-of-type(2):before {.contain('@{icon}/Arena_mode_w.svg');}
        &:nth-of-type(3):before {.contain('@{icon}/Arena_date_w.svg');}
        &:nth-of-type(4):before {.contain('@{icon}/Arena_time_w.svg');}
        span {.c(rgba(255, 255, 255, .7));
          &:after { .cnt; .ib; .m(0, 8); .vat; .mt(3); .wh(1, 14); .bgc(rgba(255, 255, 255, .7));}
        }
      }
    }
    > button { .wh(100%, 56); .bgc(#d13639); .fs(18); .br(30); .c(white); .mt(24); .extra-bold; .font-russo;
      span {.ib; .vam;
        &:after {.cnt; .ib; .wh(24); .vam; .contain('@{icon}/challenge.svg'); .mb(4); .ml(2);}
      }
    }
  }
  &.off {
    &:hover:not(.moving) { // .w(300); .t-y(-30);
      // .card-holder{ .wh(100%,330);}
      // .char-holder{.wh(100%,330);
      //   img{.wh(100%);}
      //   .bg{.wh(100%,300);}
      // }
    }
  }
  &.on { .w(280); .t-y(0);
    .card-holder { .wh(100%, 300);
      .title {.t(220);}
    }
    .char-holder {.wh(100%, 300);
      img {.wh(100%);}
      .bg {.wh(100%, 280); .br-t(8);}
    }
    .info-holder {.visible; .o(1); .t-y(0);}
  }

  &.tft {
    .char-holder {
      &::before { .cnt; .abs; .lb(0, 0); .z(2); .wh(100%, 79%); background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%); }
    }
    .card-holder {
      .title {
        h4 { text-shadow: none; }
      }
    }
  }
  @media (@tl-up) {
    .w(310);
    .card-holder {.h(340);
      .date-box { .l(30);
        .inner {
          .today {}
        }
      }
      .title { .lt(20, 260); width: calc(100% - 40px);
        h4 {.fs(24, 28px); .max-w(270);}
      }
    }
    .char-holder {.wh(100%, 340);
      img {.wh(310, 340);}
      .bg {.wh(310);}
    }
    &.off {
      &:hover:not(.moving) { // .w(340);
        //.card-holder{ .wh(100%,380);}
        //.char-holder{.wh(100%,380);
        //  img{.wh(100%);}
        //  .bg{.wh(100%,340);}
        //}
      }
    }
    &.on {.w(340); .t-y(-30);
      .card-holder { .wh(100%, 380);
        .title {.t(290);}
      }
      .char-holder {.wh(100%, 380);
        img {.wh(100%);}
        .bg {.wh(100%, 340);}
      }
      .info-holder {.w(100%);
        > button {
          .fs(18);
        }
      }
    }
  }
  @media (@ds-up) {
    .info-holder {
      > button {
        .fs(21);
      }
    }
  }
}
</style>
