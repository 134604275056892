/**
 * Each gameId should contain follow:
 * style: Object,
 * isBgExist: Boolean
 * weeksKv: Object,
 * tabs: Array<String>
 * q: String
 * stepTime: Array<String>
 * dummyTabIndex: ?Number
 * dummyCards: Array
 */

export default {
  lol_academia: {
    style: { 'background-color': '#191919' },
    isBgExist: true,
    weeksKv: {
      w1: { s: '2021-06-21', e: '2021-06-28 00:00' },
      w2: { s: '2021-06-28', e: '2021-07-05 00:00' },
      w3: { s: '2021-07-05', e: '2021-07-17 00:00' },
      w4: { s: '2021-07-17', e: '2021-07-18 00:00' },
    },
    tabs: ['1주차', '2주차', '3주차', '파이널'],
    q: 'groupCode eq RIOT_BATTLE_ACADEMIA_1',
    stepTime: ['2021-06-25 18:00', '2021-07-02 18:00', '2021-07-12 09:00', '2021-07-20'],
    dummyTabIndex: null,
    dummyCards: [],
    discordLink: 'https://discord.gg/PkpEJxM98K',
  },
  lol_donation: {
    isBgExist: true,
    q: 'groupCode eq RIOT_LOL_DOCUP_1',
    discordLink: 'https://discord.gg/PkpEJxM98K',
  },
  wr_wariwari: {
    style: { 'background-color': '#1a1d22' },
    isBgExist: false,
    weeksKv: {
      w1: { s: '2021-07-16', e: '2021-08-04 00:00' },
      w2: { s: '2021-08-05', e: '2021-08-06 00:00' },
      w3: { s: '2021-08-06', e: '2021-08-25 00:00' },
    },
    tabs: ['예선', '본선', '결선'],
    q: 'groupCode eq RIOT_WARI_WARI_1',
    stepTime: ['2021-08-05 00:00', '2021-08-06 00:00', '2021-09-01'],
    dummyTabIndex: null,
    dummyCards: [],
    discordLink: 'https://discord.gg/PkpEJxM98K',
  },
  val_shotval: {
    style: {},
    isBgExist: false,
    tabs: [
      { label: '전체', q: 'groupCode eq RIOT_SHOT_VAL_1', order: 'schedule.start ASC' },
      { label: '골드', q: 'groupCode eq RIOT_SHOT_VAL_1_GOLD', order: 'schedule.start ASC' },
      { label: '다이아', q: 'groupCode eq RIOT_SHOT_VAL_1_DIA', order: 'schedule.start ASC' },
      { label: '레디언트', q: 'groupCode eq RIOT_SHOT_VAL_1_RADIANT', order: 'schedule.start ASC' },
    ],
    q: 'groupCode eq RIOT_SHOT_VAL_1',
    stepTime: ['2021-08-05 00:00', '2021-08-06 00:00', '2021-09-01'],
    dummyTabIndex: null,
    dummyCards: [],
    discordLink: 'https://discord.gg/PkpEJxM98K',
  },
  tft_weeklycup: {
    tabs: [
      { label: 'TFT', q: 'groupCode eq RIOT_TFT_WEEKLY_1', order: 'schedule.start ASC' },
    ],
    isBgExist: false,
    style: { 'background-image': '/img/pages/tftWeeklyCup/home/BG.jpg' },
    // weeksKv: {
    //   w1: {s: '2022-01-17', e: '2022-01-27'},
    //   w2: {s: '2022-01-27', e: '2022-02-19'},
    //   w3: {s: '2022-02-19', e: '2022-02-26'},
    //   w4: {s: '2022-02-26', e: '2022-03-05'},
    //   w5: {s: '2022-03-05', e: '2022-03-12'},
    // },
    q: 'groupCode eq RIOT_TFT_WEEKLY_1',
    dummyCards: [],
    discordLink: 'https://discord.gg/PkpEJxM98K',
  },
};
